import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { ConfigSettings } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useProtectionRates } from './useProtectionRates';
import { useStoreId } from './useStoreId';
import { useStoreRules } from './useStoreRules';
import { useToast } from './useToast';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['configSettings', storeId] as const;
};

// TODO: the types get messed up when not using the select function
export const useConfigSettings = <Data>(
  select?: (data: ConfigSettings) => Data,
) => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).settings.config.get(),
    select,
  });
};

export const useConfigureGsp = () => {
  const storeId = useStoreId();
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();
  const { refetch: refetchStoreRules } = useStoreRules();
  const { refetch: refetchRates } = useProtectionRates();

  return useMutation({
    mutationFn: api.store(storeId).shippingProtection.configure,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey });
      await refetchStoreRules();
      await refetchRates();
    },
  });
};

export const useIsShippingTosCheckboxEnabled = () => {
  const { data } =
    useConfigSettings(({ shippingProtection }) => shippingProtection) ?? {};

  return !!data?.widgetConfig?.shippingPlus?.isFreeShippingOptInEnabled;
};

export const useConfigSettingsUpdate = () => {
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const toast = useToast();

  return useMutation({
    mutationFn: (values: Partial<ConfigSettings>) => {
      const data = queryClient.getQueryData<ConfigSettings>(queryKey);

      if (!data) {
        return Promise.reject(new Error('No existing Config Settings Data'));
      }

      return api.store(storeId).settings.config.put({ ...data, ...values });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error) => {
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
    },
  });
};
