import { PlusIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import { TextAreaInput, TextInput, UrlInput } from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import { Action } from '~/components/ui/Action';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useIsShippingTosCheckboxEnabled,
} from '~/hooks/useConfigSettings';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';
import { ShippingPlusWidgetPreview } from './ShippingPlusWidgetPreview';

export default function ShippingPlusSettingsWidget() {
  const formId = 'shipping-protection-widget';

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const isFreeShippingOptInEnabled = useIsShippingTosCheckboxEnabled();

  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const methods = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => saveChanges({ shippingProtection: values }))(
        event,
      )
      .catch(console.error);
  };

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'widgetConfig.shippingPlus.modal.benefits',
  });

  if (!data?.widgetConfig?.shippingPlus)
    return (
      <Card>
        <Alert
          variant="info"
          title="Shipping Plus Not Configured"
          message="You must configure Shipping Plus before the widget can be edited"
        />
      </Card>
    );

  return (
    <Page
      title="Shipping Plus Widget"
      backAction={{
        accessibilityLabel: 'Back',
        to: `..`,
      }}
    >
      <div className="flex flex-col gap-4">
        <Card>
          <Card.Heading> Preview</Card.Heading>
          <ShippingPlusWidgetPreview config={methods.watch('widgetConfig')} />
        </Card>
        <form
          className="flex flex-col gap-4"
          onSubmit={submitHandler}
          id={formId}
          onReset={(e) => {
            e.preventDefault();
            methods.reset();
          }}
        >
          <Card>
            <FloatingSave
              isDirty={methods.formState.isDirty}
              isSubmitting={methods.formState.isSubmitting}
              form={formId}
            />
            <Card.Heading>Widget</Card.Heading>

            <TextInput
              required
              id="widget-label"
              details="The primary text of the widget"
              label="Title"
              {...methods.register('widgetConfig.shippingPlus.label')}
              error={
                methods.formState.errors.widgetConfig?.shippingPlus?.label
                  ?.message
              }
            />

            {isFreeShippingOptInEnabled && (
              <TextInput
                id="widget-label"
                details="The primary text of the widget when Free Shipping Terms of Service is displayed"
                label="Free Shipping Title"
                {...methods.register(
                  'widgetConfig.shippingPlus.freeShippingLabel',
                )}
                error={
                  methods.formState.errors.widgetConfig?.shippingPlus
                    ?.freeShippingLabel?.message
                }
              />
            )}

            <TextAreaInput
              id="widget-description"
              details="The secondary text of the widget"
              label="Description"
              {...methods.register('widgetConfig.shippingPlus.description')}
              error={
                methods.formState.errors.widgetConfig?.shippingPlus?.description
                  ?.message
              }
            />
            {isFreeShippingOptInEnabled && (
              <TextAreaInput
                id="widget-description"
                details="The secondary text of the widget when Free Shipping Terms of Service is displayed"
                label="Free Shipping Description"
                {...methods.register(
                  'widgetConfig.shippingPlus.freeShippingDescription',
                )}
                error={
                  methods.formState.errors.widgetConfig?.shippingPlus
                    ?.freeShippingDescription?.message
                }
              />
            )}
          </Card>

          <Card>
            <Card.Heading>Modal</Card.Heading>
            <UrlInput
              required
              id="modal-image-url"
              label="Modal Image URL"
              {...methods.register('widgetConfig.shippingPlus.modal.imageUrl')}
              error={
                methods.formState.errors.widgetConfig?.shippingPlus?.modal
                  ?.imageUrl?.message
              }
            />

            <TextInput
              id="modal-title"
              label="Modal Title"
              {...methods.register('widgetConfig.shippingPlus.modal.title')}
              required
              error={
                methods.formState.errors.widgetConfig?.shippingPlus?.modal
                  ?.title?.message
              }
            />

            <TextAreaInput
              id="modal-description"
              label="Modal Description"
              {...methods.register(
                'widgetConfig.shippingPlus.modal.description',
              )}
              error={
                methods.formState.errors.widgetConfig?.shippingPlus?.modal
                  ?.description?.message
              }
            />
          </Card>

          <Card>
            <div className="flex items-center gap-2">
              <Card.Heading>Modal Benefits</Card.Heading>
              <Action
                icon={PlusIcon}
                variant="ghost"
                accessibilityLabel="Add benefit"
                onClick={() =>
                  append({
                    title: '',
                    description: '',
                  })
                }
              />
            </div>
            {fields.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-2">
                <TextInput
                  id={`benefit-title-${index}`}
                  label="Title"
                  required
                  aria-describedby={`benefit-title-error-${index}`}
                  {...methods.register(
                    `widgetConfig.shippingPlus.modal.benefits.${index}.title`,
                  )}
                  error={
                    methods.formState.errors?.widgetConfig?.shippingPlus?.modal
                      ?.benefits?.[index]?.title?.message
                  }
                />

                <TextAreaInput
                  id={`benefit-description-${index}`}
                  label="Description"
                  aria-describedby={`benefit-description-error-${index}`}
                  {...methods.register(
                    `widgetConfig.shippingPlus.modal.benefits.${index}.description`,
                  )}
                  required
                  error={
                    methods.formState.errors?.widgetConfig?.shippingPlus?.modal
                      ?.benefits?.[index]?.description?.message
                  }
                />

                <div className="self-end">
                  <Action onClick={() => remove(index)} variant="destructive">
                    Remove
                  </Action>
                </div>
              </div>
            ))}
          </Card>
        </form>
      </div>
    </Page>
  );
}
