import { PlusIcon } from '@heroicons/react/20/solid';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Pegasus, isTruthy } from 'corso-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import { NumberInput, SwitchInput, TextInput } from '~/components/field';
import Menu from '~/components/Menu';
import { Action } from '~/components/ui/Action';
import { MultiSelect, MultiSelectOption } from '~/components/ui/MultiSelect';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useIsShippingTosCheckboxEnabled } from '~/hooks/useConfigSettings';
import { useCurrency } from '~/hooks/useCurrency';
import { useProtectionRates } from '~/hooks/useProtectionRates';
import { useHasCheckoutFunctionality } from '~/hooks/useShopifyPlan';
import { useStoreTimezone } from '~/hooks/useStoreTimezone';
import { ShippingRate } from '~/pages/settings/ShippingRate';
import { StoreRuleFormData } from '~/types';
import {
  discountTypeText,
  estimatedDeliveryShippingCutOffOptions,
  usHolidayOptions,
} from '~/utils/enumNameMaps';

const modifyQuoteOptionText = {
  APPLY_FIXED_FEE: 'Apply Fixed Fee',
  APPLY_PERCENTAGE_FEE: 'Apply Percentage Fee',
  ADD_OR_REMOVE_RATES: 'Modify Provided Rates',
  PROVIDE_ESTIMATED_DELIVERY_DATE: 'Provide An Estimated Delivery Date',
  APPLY_SHIPPING_DISCOUNT: 'Apply Shipping Discount',
} as const satisfies Record<Pegasus.QuoteModification, string>;

const discountTypeOptions = Object.values(Pegasus.ShippingDiscountType).map(
  (type) => ({
    value: type,
    label: discountTypeText[type],
  }),
);

const defaultQuoteModifyOptions = Object.values(Pegasus.QuoteModification)
  .map((kind) => ({
    value: kind,
    label: modifyQuoteOptionText[kind],
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

function getModificationOptions(currentOptions: Pegasus.QuoteModification[]) {
  return defaultQuoteModifyOptions.filter(
    (option) =>
      option.value === 'PROVIDE_ESTIMATED_DELIVERY_DATE' ||
      !currentOptions.includes(option.value),
  );
}

const getDiscountTypeOptions = (canUsePlusPricing: boolean) => {
  if (canUsePlusPricing) {
    return discountTypeOptions;
  }

  return discountTypeOptions.filter(
    (option) => option.value !== 'removePlusPricing',
  );
};

export default function EventQuoteOrderShipmentModify() {
  const { control, watch, register } = useFormContext<StoreRuleFormData>();

  const isShippingTosEnabled = useIsShippingTosCheckboxEnabled();

  const { data: protectionRates } = useProtectionRates();

  const hasCheckoutExtension = useHasCheckoutFunctionality();

  const { currencyCode, currencySymbol } = useCurrency();

  const timezone = useStoreTimezone();

  const {
    fields: checkoutRateModifications,
    append,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: 'event.params.checkoutRateModifications',
  });

  const currentOptions = watch('event.params.checkoutRateModifications').map(
    (m) => m.kind,
  );

  const canUsePlusPricingDiscount =
    isShippingTosEnabled && hasCheckoutExtension;

  const modificationOptions = getModificationOptions(currentOptions);

  const protectionRateOptions =
    protectionRates?.map(
      (rate) =>
        ({
          value: `${rate.id}`,
          keywords: [rate.name, rate.description].filter(isTruthy),
          label: <ShippingRate rate={rate} />,
        }) satisfies MultiSelectOption,
    ) ?? [];

  const displayLocationOptions = [
    {
      value: 'ADD_TO_DESCRIPTION',
      label: 'Add To Description',
    },
    ...(hasCheckoutExtension ?
      [
        {
          value: 'EXTENSION' as const,
          label: 'Checkout Extension',
        },
      ]
    : []),
  ] satisfies {
    value: Pegasus.EstimatedDeliveryDisplayLocation;
    label: string;
  }[];

  const canAddModification = modificationOptions.length > 0;

  const handleAddModification = (kind: Pegasus.QuoteModification) => {
    if (kind === 'ADD_OR_REMOVE_RATES') {
      insert(0, {
        kind: 'ADD_OR_REMOVE_RATES',
        addOrRemoveStoreShipRateIds: [],
        action: 'ADD',
      });
    }

    if (kind === 'APPLY_FIXED_FEE') {
      append({
        kind: 'APPLY_FIXED_FEE',
        amount: 0,
        applyPerItem: false,
        fixedFeeStoreShipRateIds: [],
      });
    }

    if (kind === 'APPLY_PERCENTAGE_FEE') {
      append({
        kind: 'APPLY_PERCENTAGE_FEE',
        percentage: 0,
        percentageFeeStoreShipRateIds: [],
      });
    }

    if (kind === 'PROVIDE_ESTIMATED_DELIVERY_DATE') {
      append({
        kind: 'PROVIDE_ESTIMATED_DELIVERY_DATE',
        strategy: 'STATIC',
        estimatedDeliveryDisplayLocation: 'ADD_TO_DESCRIPTION',
        estimatedDeliveryRangeStartDays: 3,
        estimatedDeliveryRangeEndDays: 7,
        estimatedTargetStoreShipRateIds: [],
        estimatedDeliveryShippingCutOffTime: 0,
        estimatedDeliveryFulfillmentTz: timezone,
        estimatedDeliveryHolidays: Object.values(Pegasus.USHoliday),
      });
    }

    if (kind === 'APPLY_SHIPPING_DISCOUNT') {
      append({
        kind: 'APPLY_SHIPPING_DISCOUNT',
        discountAmount: 0,
        discountType: 'fixedAmount',
        discountMessage: '',
        discountStoreShipRateIds: [],
        isCheckboxApplicationEnabled: isShippingTosEnabled,
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {checkoutRateModifications.map((field, index) => {
        const kind = watch(
          `event.params.checkoutRateModifications.${index}.kind`,
        );

        const isCheckboxApplicationEnabled = watch(
          `event.params.checkoutRateModifications.${index}.isCheckboxApplicationEnabled`,
        );

        const estimateStrategy = watch(
          `event.params.checkoutRateModifications.${index}.strategy`,
        );

        const modificationText = watch(
          `event.params.checkoutRateModifications.${index}.action`,
        );

        const discountType = watch(
          `event.params.checkoutRateModifications.${index}.discountType`,
        );

        const modificationDetail =
          // eslint-disable-next-line no-nested-ternary
          modificationText === 'ADD' ? 'add to the previously provided rates.'
          : modificationText === 'REMOVE' ?
            'remove from the previously provided rates.'
          : 'to replace the previously provided rates with.';

        return (
          <Card key={field.id}>
            <Card.Heading> {modifyQuoteOptionText[kind]}</Card.Heading>

            {kind === 'APPLY_SHIPPING_DISCOUNT' && (
              <>
                {isShippingTosEnabled && (
                  <Controller
                    control={control}
                    name={`event.params.checkoutRateModifications.${index}.isCheckboxApplicationEnabled`}
                    render={({ field: f }) => (
                      <SwitchInput
                        id={`checkbox-application-enabled-${index}`}
                        label="Enable Checkbox Application"
                        details="When enabled, the discount will be applied when the customer selects the Shipping TOS checkbox."
                        checked={f.value}
                        onChange={f.onChange}
                      />
                    )}
                  />
                )}

                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.discountType`}
                  render={({ field: f }) => (
                    <SimpleSelect
                      options={getDiscountTypeOptions(
                        canUsePlusPricingDiscount,
                      )}
                      label="Discount Type"
                      value={f.value}
                      onChange={f.onChange}
                      required
                    />
                  )}
                />

                {discountType === 'fixedAmount' && (
                  <Controller
                    control={control}
                    name={`event.params.checkoutRateModifications.${index}.discountAmount`}
                    render={({ field: f, fieldState }) => (
                      <NumberInput
                        step={0.01}
                        addon={{
                          insideStart: currencySymbol,
                          insideEnd: currencyCode,
                        }}
                        label="Amount"
                        details="The amount to apply as a discount."
                        value={f.value}
                        id={`discount-modification-amount-${index}`}
                        error={fieldState.error?.message}
                        onChange={(e) => f.onChange(e.target.valueAsNumber)}
                      />
                    )}
                  />
                )}

                {discountType === 'percentage' && (
                  <Controller
                    control={control}
                    name={`event.params.checkoutRateModifications.${index}.discountAmount`}
                    render={({ field: f, fieldState }) => (
                      <NumberInput
                        step={1}
                        max={100}
                        value={f.value && f.value * 100}
                        id={`discount-percentage-modification-amount-${index}`}
                        label="Percentage"
                        addon={{ insideEnd: '%' }}
                        details="The percentage to apply as a discount."
                        error={fieldState.error?.message}
                        onChange={(e) =>
                          f.onChange(e.target.valueAsNumber / 100)
                        }
                      />
                    )}
                  />
                )}

                <TextInput
                  id="discount-message"
                  label="Discount Message"
                  details="The message to display to the customer when the discount is applied."
                  {...register(
                    `event.params.checkoutRateModifications.${index}.discountMessage`,
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.discountStoreShipRateIds`}
                  render={({ field: f, fieldState }) => (
                    <MultiSelect
                      estimateSize={114}
                      label="Shipping Rates"
                      options={protectionRateOptions}
                      details="The rates to apply the discount to, if left empty all rates will have the discount applied."
                      placeholder="Select Rates"
                      value={f.value
                        ?.map((value) =>
                          protectionRateOptions.find(
                            (option) => option.value === `${value}`,
                          ),
                        )
                        .filter(isTruthy)}
                      onChange={(selected) =>
                        f.onChange(selected.map(({ value }) => Number(value)))
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />

                {isCheckboxApplicationEnabled && (
                  <Alert
                    variant="info"
                    title="Shipping TOS Checkbox Enabled"
                    message="This discount will be applied when the customer selects the Shipping TOS checkbox."
                  />
                )}
              </>
            )}
            {kind === 'APPLY_FIXED_FEE' && (
              <>
                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.amount`}
                  render={({ field: f, fieldState }) => (
                    <NumberInput
                      step={0.01}
                      addon={{
                        insideStart: currencySymbol,
                        insideEnd: currencyCode,
                      }}
                      label="Amount"
                      details="The amount to apply as a handling fee."
                      value={f.value}
                      id={`modification-amount-${index}`}
                      error={fieldState.error?.message}
                      onChange={(e) => f.onChange(e.target.valueAsNumber)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.applyPerItem`}
                  render={({ field: f }) => (
                    <SwitchInput
                      id={`apply-per-item-${index}`}
                      label="Apply Per Item"
                      details="When enabled, the fee will be applied for each item in the order."
                      checked={f.value}
                      onChange={f.onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.fixedFeeStoreShipRateIds`}
                  render={({ field: f, fieldState }) => (
                    <MultiSelect
                      estimateSize={114}
                      label="Shipping Rates"
                      options={protectionRateOptions}
                      details="The rates to apply the fee, if left empty all rates will have the fee applied."
                      placeholder="Select Rates"
                      value={f.value
                        ?.map((value) =>
                          protectionRateOptions.find(
                            (option) => option.value === `${value}`,
                          ),
                        )
                        .filter(isTruthy)}
                      onChange={(selected) =>
                        f.onChange(selected.map(({ value }) => Number(value)))
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}

            {kind === 'PROVIDE_ESTIMATED_DELIVERY_DATE' && (
              <>
                {/* Implement Multi-Select Here once we have defined additional strategies */}

                {estimateStrategy === 'STATIC' && (
                  <>
                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedDeliveryDisplayLocation`}
                      render={({ field: f, fieldState }) => (
                        <SimpleSelect
                          label="Display Location"
                          options={displayLocationOptions}
                          onChange={f.onChange}
                          value={f.value}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    {/* // TODO get back to undefined after entering a value on these inputs */}
                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedDeliveryRangeStartDays`}
                      render={({ field: f, fieldState }) => (
                        <NumberInput
                          id={`estimated-delivery-range-start-${index}`}
                          label="Estimated Delivery Start"
                          details="The number of business days to the earliest possible delivery date."
                          addon={{ insideEnd: 'days' }}
                          value={f.value}
                          error={fieldState.error?.message}
                          onChange={(e) => f.onChange(e.target.valueAsNumber)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedDeliveryRangeEndDays`}
                      render={({ field: f, fieldState }) => (
                        <NumberInput
                          id={`estimated-delivery-range-end-${index}`}
                          label="Estimated Delivery End"
                          details="The number of business days to the latest possible delivery date."
                          value={f.value}
                          addon={{ insideEnd: 'days' }}
                          error={fieldState.error?.message}
                          onChange={(e) => f.onChange(e.target.valueAsNumber)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedDeliveryShippingCutOffTime`}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <SimpleSelect
                          options={estimatedDeliveryShippingCutOffOptions.map(
                            (option) => ({
                              value: `${option.value}`,
                              label: option.label,
                            }),
                          )}
                          details="The time of day to cut off shipping for the estimated delivery date."
                          label="Shipping Cut Off Time"
                          onChange={onChange}
                          value={`${value}`}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedDeliveryHolidays`}
                      render={({ field: f, fieldState }) => (
                        <MultiSelect
                          estimateSize={114}
                          label="Holidays"
                          options={usHolidayOptions}
                          details="The US Holidays to use when calculating the estimated delivery date."
                          placeholder="Select Holidays"
                          value={f.value
                            ?.map((value) =>
                              usHolidayOptions.find(
                                (option) => option.value === `${value}`,
                              ),
                            )
                            .filter(isTruthy)}
                          onChange={(selected) =>
                            f.onChange(
                              selected.map(({ value }) => value), // Ensure holiday values are passed back in the correct format
                            )
                          }
                          error={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`event.params.checkoutRateModifications.${index}.estimatedTargetStoreShipRateIds`}
                      render={({ field: f, fieldState }) => (
                        <MultiSelect
                          estimateSize={114}
                          label="Shipping Rates"
                          options={protectionRateOptions}
                          details="The rates to apply the delivery estimate to, if left empty all rates will be updated."
                          placeholder="Select Rates"
                          value={f.value
                            .map((value) =>
                              protectionRateOptions.find(
                                (option) => option.value === `${value}`,
                              ),
                            )
                            .filter(isTruthy)}
                          onChange={(selected) =>
                            f.onChange(
                              selected.map(({ value }) => Number(value)),
                            )
                          }
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </>
                )}

                {/* Implement the dynamic section here at some future point */}
              </>
            )}

            {kind === 'APPLY_PERCENTAGE_FEE' && (
              <>
                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.percentage`}
                  render={({ field: f, fieldState }) => (
                    <NumberInput
                      step={1}
                      max={100}
                      value={f.value && f.value * 100}
                      id={`modification-amount-${index}`}
                      label="Percentage"
                      addon={{ insideEnd: '%' }}
                      details="The percentage to apply as a handling fee, based on the rate price."
                      error={fieldState.error?.message}
                      onChange={(e) => f.onChange(e.target.valueAsNumber / 100)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.percentageFeeStoreShipRateIds`}
                  render={({ field: f, fieldState }) => (
                    <MultiSelect
                      estimateSize={114}
                      label="Shipping Rates"
                      options={protectionRateOptions}
                      details="The rates to apply the fee, if left empty all rates will have the fee applied."
                      placeholder="Select Rates"
                      value={f.value
                        ?.map((value) =>
                          protectionRateOptions.find(
                            (option) => option.value === `${value}`,
                          ),
                        )
                        .filter(isTruthy)}
                      onChange={(selected) =>
                        f.onChange(selected.map(({ value }) => Number(value)))
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}

            {kind === 'ADD_OR_REMOVE_RATES' && (
              <>
                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.action`}
                  render={({ field: f, fieldState }) => (
                    <SimpleSelect
                      label="Action"
                      options={
                        [
                          { value: 'ADD', label: 'Add' },
                          { value: 'REMOVE', label: 'Remove' },
                          { value: 'REPLACE', label: 'Replace' },
                        ] satisfies {
                          value: Pegasus.AddOrRemoveRateAction;
                          label: string;
                        }[]
                      }
                      onChange={f.onChange}
                      value={f.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`event.params.checkoutRateModifications.${index}.addOrRemoveStoreShipRateIds`}
                  render={({ field: f, fieldState }) => (
                    <MultiSelect
                      estimateSize={114}
                      label="Shipping Rates"
                      options={protectionRateOptions}
                      details={`The rates to ${modificationDetail}`}
                      placeholder="Select Rates"
                      value={f.value
                        .map((value) =>
                          protectionRateOptions.find(
                            (option) => option.value === `${value}`,
                          ),
                        )
                        .filter(isTruthy)}
                      onChange={(selected) =>
                        f.onChange(selected.map(({ value }) => Number(value)))
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}

            <div className="self-end">
              <Action
                onClick={() => {
                  remove(index);
                }}
                variant="destructive"
              >
                Remove
              </Action>
            </div>
          </Card>
        );
      })}

      <Separator />
      {canAddModification && (
        <div className="flex items-end">
          <Menu
            buttonAs={
              <Action icon={PlusIcon} disabled={!canAddModification}>
                Add Modification
              </Action>
            }
          >
            {modificationOptions.map((option) => (
              <Menu.ItemButton
                key={option.value}
                onClick={() => handleAddModification(option.value)}
              >
                {option.label}
              </Menu.ItemButton>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
}
