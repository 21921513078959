import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { faBox, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { isTruthy, Pegasus } from 'corso-types';

import { FormEventHandler, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import EmptyStateAction from '~/components/EmptyStateAction';
import SwitchInput from '~/components/field/SwitchInput';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import { Action } from '~/components/ui/Action';
import { MultiSelect, MultiSelectOption } from '~/components/ui/MultiSelect';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useConfigureGsp,
} from '~/hooks/useConfigSettings';
import useIsCorsoAdmin from '~/hooks/useIsCorsoAdmin';
import useIsTest from '~/hooks/useIsTest';
import { useProtectionRates } from '~/hooks/useProtectionRates';
import {
  useHasCheckoutFunctionality,
  useShopifyPlan,
} from '~/hooks/useShopifyPlan';
import { useStoreId } from '~/hooks/useStoreId';
import { useStoreRules } from '~/hooks/useStoreRules';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';
import { ShippingPlusWidgetPreview } from './ShippingPlusWidgetPreview';
import { ShippingRate } from './ShippingRate';
import ManageShippingRates from './ShippingRates/ManageShippingRates';
import ShippingRateForm from './ShippingRates/ShippingRateForm';
import { StoreRuleDisplay } from './storeRules/StoreRuleDisplay';

function ShippingRates() {
  const { data: protectionRates, refetch: refetchProtectionRates } =
    useProtectionRates();

  const [showForm, setShowForm] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hideRates, setShowHideRates] = useState(false);
  const { mutate: configureSp, isPending } = useConfigureGsp();

  const ratesExist = protectionRates && protectionRates.length > 0;

  return (
    <Card>
      <div className="flex flex-row items-center justify-between">
        <Card.Heading>Shipping Rates</Card.Heading>

        <div className="flex space-x-2">
          <Action
            icon={hideRates ? faEye : faEyeSlash}
            onClick={() => setShowHideRates(!hideRates)}
            accessibilityLabel="Collapse Rates"
            variant="ghost"
          />
          <Action
            icon={faShopify}
            loading={isPending}
            onClick={() => setShowConfirmationModal(true)}
            accessibilityLabel="Refresh Rates from Shopify"
            variant="ghost"
          />
          <Action
            icon={PlusIcon}
            onClick={() => setShowForm(true)}
            accessibilityLabel="Add Rate"
            variant="ghost"
          />
        </div>

        <ConfirmModal
          prompt="Are you sure you'd like to sync your Shipping Rates and Shipping Zone Rules?"
          title="Sync Rates And Shipping Zones"
          show={showConfirmationModal}
          confirmText="Confirm"
          variant="primary"
          onConfirm={() => {
            configureSp(true);
            setShowConfirmationModal(false);
            refetchProtectionRates().catch(console.error);
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </div>

      {/* eslint-disable-next-line no-nested-ternary */}
      {!ratesExist ?
        <EmptyStateAction.Button
          onClick={() => setShowForm(true)}
          icon={<FontAwesomeIcon icon={faBox} />}
          label="Create A Shipping Rate"
        />
      : hideRates ?
        null
      : <ManageShippingRates rates={protectionRates} editable />}

      <ShippingRateForm show={showForm} onClose={() => setShowForm(false)} />
    </Card>
  );
}

function ShippingZoneRules() {
  const location = useLocation();
  const storeId = useStoreId();
  const { data: storeRules } = useStoreRules();

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const quoteShipmentAutomationRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'quoteOrderShipment')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  const enabledQuoteShipmentAutomationRules =
    quoteShipmentAutomationRules.filter((rule) => rule.isEnabled);
  if (!data) return null;

  const { isShippingLineProtectionOffered } = data;

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Card.Heading>Shipping Zone Rules</Card.Heading>

        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Add New Shipping Zone Rule"
          to={{
            pathname: `/${storeId}/settings/automations/create`,
            search: new URLSearchParams({
              eventType: Pegasus.EventType.quoteOrderShipment,
              returnTo: location.pathname,
            }).toString(),
          }}
        />
      </div>

      {!enabledQuoteShipmentAutomationRules.length && (
        <Alert
          title="No Rules Configured or Enabled"
          message={
            isShippingLineProtectionOffered ?
              'Shipping Plus is enabled, but no Shipping Zone Rules have been configured and enabled. This could result in customers not able to checkout.'
            : 'To offer Shipping Plus, you must first configure or enable a Shipping Zone Rule.'
          }
          variant={isShippingLineProtectionOffered ? 'warning' : 'DEFAULT'}
        />
      )}

      <ul className="flex flex-col gap-2">
        {quoteShipmentAutomationRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              returnTo={location.pathname}
            />
          </li>
        ))}
      </ul>
    </Card>
  );
}

function ShippingRateModificationRules() {
  const location = useLocation();
  const storeId = useStoreId();
  const { data: storeRules } = useStoreRules();

  const quoteShipmentModifyRateRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'quoteOrderShipmentModify')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Card.Heading>Shipping Rate Modification Rules</Card.Heading>

        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Add New Shipping Rate Modification Rule"
          to={{
            pathname: `/${storeId}/settings/automations/create`,
            search: new URLSearchParams({
              eventType: Pegasus.EventType.quoteOrderShipmentModify,
              returnTo: location.pathname,
            }).toString(),
          }}
        />
      </div>

      <ul className="flex flex-col gap-2">
        {quoteShipmentModifyRateRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              returnTo={location.pathname}
            />
          </li>
        ))}
      </ul>
    </Card>
  );
}

function ThemeAppExtensionLoadRules() {
  const location = useLocation();
  const storeId = useStoreId();
  const { data: storeRules } = useStoreRules();

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const themeAppExtensionLoadRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'themeAppExtensionLoad')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  if (!data) return null;

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Card.Heading>Theme App Delivery Estimate Rules</Card.Heading>

        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Add New Theme App Delivery Estimate Rule"
          to={{
            pathname: `/${storeId}/settings/automations/create`,
            search: new URLSearchParams({
              eventType: Pegasus.EventType.themeAppExtensionLoad,
              returnTo: location.pathname,
            }).toString(),
          }}
        />
      </div>

      <ul className="flex flex-col gap-2">
        {themeAppExtensionLoadRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              returnTo={location.pathname}
            />
          </li>
        ))}
      </ul>
    </Card>
  );
}

function AppUpgradeRequired() {
  return (
    <Page title="Shipping Plus">
      <Card>
        <Alert
          title="App Upgrade Required"
          message="In order to use Shipping Plus, you must upgrade your Corso app to the latest version. This can be done by selecting the Corso app in your Shopify admin and clicking the 'Update' button."
          variant="DEFAULT"
        />
      </Card>
    </Page>
  );
}

export default function ShippingPlusSettings() {
  const formId = 'shipping-protection-settings';
  const formRef = useRef<HTMLFormElement>(null);

  const { data: protectionRates } = useProtectionRates();

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const methods = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => saveChanges({ shippingProtection: values }))(
        event,
      )
      .catch(console.error);
  };

  const {
    storeUser: {
      store: { id: storeId },
    },
  } = useMerchantContext();

  const protectionRateOptions =
    protectionRates?.map(
      (rate) =>
        ({
          value: `${rate.id}`,
          keywords: [rate.name, rate.description].filter(isTruthy),
          label: <ShippingRate rate={{ ...rate, isPlusRate: false }} />,
        }) satisfies MultiSelectOption,
    ) ?? [];

  const {
    isShippingLineProtectionOffered,
    hasWriteShippingScope,
    hasDeliveryCustomizationScope,
    isApplicationDiscountConfigured,
  } = data ?? {};

  const shopifyPlan = useShopifyPlan();
  const showCheckoutSection = useHasCheckoutFunctionality();
  const isCorsoAdmin = useIsCorsoAdmin();
  const isTest = useIsTest();
  const isAdminOrTest = isCorsoAdmin || isTest;

  const displayPlanAlert = shopifyPlan === 'Basic' || shopifyPlan === 'Shopify';

  const isEligible = hasWriteShippingScope && hasDeliveryCustomizationScope;
  const shippingTosEnabled = methods.watch(
    'widgetConfig.shippingPlus.isFreeShippingOptInEnabled',
  );

  if (!isEligible) return <AppUpgradeRequired />;

  return (
    <Page
      title="Shipping Plus"
      secondaryActions={[
        isShippingLineProtectionOffered && {
          id: 'disable-shipping-plus',
          content: 'Turn Off',
          onAction: () => {
            methods.setValue('isShippingLineProtectionOffered', false, {
              shouldDirty: true,
            });
            methods.setValue('widgetConfig.shippingPlus.isEnabled', false, {
              shouldDirty: true,
            });
            formRef.current?.requestSubmit();
          },
        },
      ].filter(isTruthy)}
      primaryAction={
        isShippingLineProtectionOffered ? undefined : (
          {
            onAction: () => {
              methods.setValue('isShippingLineProtectionOffered', true, {
                shouldDirty: true,
              });
              methods.setValue('widgetConfig.shippingPlus.isEnabled', true, {
                shouldDirty: true,
              });
              formRef.current?.requestSubmit();
            },
            content: 'Turn On',
          }
        )
      }
    >
      <form
        ref={formRef}
        className="flex flex-col gap-4"
        id={formId}
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          methods.reset();
        }}
      >
        <div className="flex flex-col gap-4">
          <FloatingSave
            isDirty={methods.formState.isDirty}
            isSubmitting={methods.formState.isSubmitting}
            form={formId}
          />

          {!isShippingLineProtectionOffered && (
            <Alert
              variant="warning"
              title="Shipping Plus is Disabled"
              message="Settings can be configured, but no rates will be provided to customers."
            />
          )}

          {displayPlanAlert && (
            <Card>
              <Alert
                variant="DEFAULT"
                title="Shopify & Shopify Basic"
                message="Your Shopify plan may not support the features required to use Shipping Plus. Please contact us for more information."
              />
            </Card>
          )}

          <ShippingZoneRules />
          <ShippingRateModificationRules />
          <ShippingRates />

          <Card>
            <Card.Heading>Backup Shipping Rates</Card.Heading>
            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.backupRateIds"
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  estimateSize={114}
                  labelVisuallyHidden
                  label="Backup Rates"
                  options={protectionRateOptions}
                  details="The rates to use as a backup if for any reason the Shipping Plus service is unavailable, or delayed. In the event of a failure, or misconfiguration these rates will be used to ensure checkout is still possible."
                  placeholder="Select Rates"
                  value={f.value
                    ?.map((value) =>
                      protectionRateOptions.find(
                        (option) => option.value === `${value}`,
                      ),
                    )
                    .filter(isTruthy)}
                  onChange={(selected) =>
                    f.onChange(selected.map(({ value }) => Number(value)))
                  }
                  error={fieldState.error?.message}
                />
              )}
            />
          </Card>
        </div>

        {showCheckoutSection && (
          <>
            <Card>
              <Card.Heading>Checkout Extension</Card.Heading>

              <Controller
                control={methods.control}
                name="isWidgetProtectionOffered"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="offer-shipping-protection"
                    label="Show Checkout Widget"
                    details="When enabled, the checkout widget can be used to display additional information about the Shipping Plus experience."
                    checked={!!value}
                    onChange={onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />

              {isAdminOrTest && (
                <Controller
                  control={methods.control}
                  name="widgetConfig.shippingPlus.isWidgetDebugEnabled"
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SwitchInput
                      id="debug-checkout-widget"
                      label="Debug Mode"
                      details="When enabled, additional debug information will be displayed in the console."
                      checked={!!value}
                      onChange={onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              )}

              <div className="flex items-center gap-2">
                <Card.Heading>Checkout Widget Preview </Card.Heading>
                <Action
                  icon={PencilIcon}
                  variant="ghost"
                  accessibilityLabel="Edit Widget"
                  to={`/${storeId}/settings/shipping-plus/widget`}
                />
              </div>
              <ShippingPlusWidgetPreview
                config={methods.watch('widgetConfig')}
              />
            </Card>

            <Card>
              <div className="flex flex-row justify-between">
                <Card.Heading>Advanced Settings</Card.Heading>
              </div>

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.shouldUseDiscountedPrice"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="use-discounted-price"
                    label="Use Discounted Price for Rate Conditions"
                    details="When enabled, Plus rate conditions will be calculated based on the discounted price of the cart. "
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.isFreeShippingOptInEnabled"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="tos-shipping-checkbox"
                    label="Shipping Terms of Service Checkbox"
                    details="When enabled, customers can accept terms, in order to have a discount applied to their shipping."
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />
              {!isApplicationDiscountConfigured && shippingTosEnabled && (
                <Alert
                  variant="warning"
                  message={
                    <div>
                      Application Discount has not been configured, please save
                      your settings to enable.
                    </div>
                  }
                />
              )}
            </Card>
          </>
        )}

        {isAdminOrTest && (
          <>
            <Card>
              <div className="flex flex-row justify-between">
                <Card.Heading>Delivery Customization</Card.Heading>

                <Controller
                  control={methods.control}
                  name="widgetConfig.shippingPlus.deliveryCustomization.isEnabled"
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SwitchInput
                      id="enable-delivery-customization"
                      label="Enable Delivery Customization"
                      labelVisuallyHidden
                      onChange={onChange}
                      checked={!!value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.deliveryCustomization.isReorderShippingLinesEnabled"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="reorder-shipping-lines"
                    label="Reorder Shipping Options"
                    details="When enabled, the Plus rates will be reordered to appear at the top of the Shipping Options."
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.deliveryCustomization.hideRateNames"
                render={({ field: f, fieldState }) => (
                  <MultiSelect
                    creatable
                    label="Hide Shipping Rates"
                    options={[]}
                    details="The rate titles to hide at checkout, these rates will not be displayed to the customer."
                    placeholder="Add Rate"
                    value={
                      f.value?.map((v) => ({
                        value: v.toString(),
                        label: v,
                      })) ?? []
                    }
                    onChange={(o) => f.onChange(o.map((v) => v.value))}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </Card>
            <Card>
              <Card.Heading> Admin Settings</Card.Heading>
              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.isUpdateExistingDiscountsEnabled"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="update-existing-discounts"
                    label="Update New And Existing Discounts"
                    details="When enabled, existing discounts will be updated to combine with Shipping discounts, and all newly created discounts will also be updated."
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </Card>
            <ThemeAppExtensionLoadRules />
          </>
        )}
      </form>
    </Page>
  );
}
